<script>
	export let src = null
	export let width = 'w-6'
	export let height = 'h-6'
	export let margin = 'mr-3'
	export let alt = null
	export let cls = null
	export let wrapcls = null
	export let smallHideText = null
</script>

{#if $$slots.default}
	<div class="flex items-center {wrapcls}">
		<img class="icon inline-block {margin} {height} {width} {cls}" {src} {alt} />
		{#if smallHideText}
			<div class="hidden sm:flex sm:items-center">
				<slot />
			</div>
		{:else}
			<slot />
		{/if}
	</div>
{:else}
	<img class="icon inline-block {margin} {height} {width} {cls}" {src} {alt} />
{/if}
